import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ws from '../../webSocket';

const serverUrl = process.env.REACT_APP_BACKEND || 'https://localhost:5000';
const apiUrl = serverUrl + '/api/v1/tanks';

const initialSubscriptionData = {
  tanks: undefined,
  currentTank: undefined,
  needsReload: false
};
const UserSubscriptionStore = createContext(initialSubscriptionData);
export { UserSubscriptionStore };
const { Provider } = UserSubscriptionStore;

UserSubscriptionProvider.propTypes = {
  children: PropTypes.any
};

export default function UserSubscriptionProvider({ children }) {
  const [subscriptionData, setSubscriptionData] = useState(
    initialSubscriptionData
  );

  useEffect(() => {
    let tank = localStorage.getItem('tank');
    console.debug(tank);

    const doFetchTanks = () => {
      console.debug('Starting attempt to fetch tanks');
      fetch(apiUrl, {
        method: 'GET',
        credentials: 'include'
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(res => {
                const tanks = res.tanks;
                if (tanks && tanks.length > 0) {
                  if (!tanks.includes(tank)) {
                    tank = tanks[0];
                    localStorage.setItem('tank', tank);
                  }

                  setSubscriptionData({
                    tanks: tanks,
                    currentTank: tank,
                    needsReload: false
                  });
                } else {
                  alert('Inga tankar tillgängliga.');
                }
              })
              .catch(error => {
                console.log('Failed to fetch tanks', error);
                setTimeout(doFetchTanks, 5000);
              });
          } else {
            response.reject(new Error('Unexpected response'));
          }
        })
        .catch(error => {
          console.log('Failed to fetch tanks', error);
          setTimeout(doFetchTanks, 5000);
        });
    };

    doFetchTanks();
  }, []);

  useEffect(() => {
    if (subscriptionData.currentTank) {
      ws.setTank(subscriptionData.currentTank);
    }
  }, [subscriptionData.currentTank]);

  useEffect(() => {
    if (subscriptionData.needsReload) {
      window.location.reload();
    }
  }, [subscriptionData.needsReload]);

  return (
    <Provider value={{ subscriptionData, setSubscriptionData }}>
      {children}
    </Provider>
  );
}
