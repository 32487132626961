import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// TODO: replace mock picture with real one.
import { PictureStore } from '../../dataStore/camera/Picture';
import PropTypes from 'prop-types';

CameraShot.propTypes = {
  maxWidth: PropTypes.number
};

const useStyles = makeStyles({
  overlayImage: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: '30%'
  }
});

export default function CameraShot(props) {
  const classes = useStyles();
  const { state } = useContext(PictureStore);

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={state.picture}
        alt="last camera shot"
        style={{ width: '100%', maxWidth: props.maxWidth }}
      />
      <img
        src="SCOTT_logo.svg"
        alt="SCOTT overlay"
        className={classes.overlayImage}
      />
    </div>
  );
}
