import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { WebSocketDataStore } from './WebSocketData';
import { UserSubscriptionStore } from '../dataStore/user/Subscription';

const serverUrl = process.env.REACT_APP_BACKEND || 'https://localhost:5000';
const apiUrl =
  serverUrl + '/api/v1/tanks/__TANK__/temps/temp0/telemetry?since=';

const initialState = [];
const WaterTemperatureStore = createContext(initialState);
export { WaterTemperatureStore };
const { Provider } = WaterTemperatureStore;

// Filter the data samples by timestamp
function filterByTimestamp(item) {
  if (item.ts * 1000 >= this) {
    return true;
  }
  return false;
}

// Max scale of data that the browser loads
const timeScale = 7 * 24 * 60 * 60 * 1000; // One week

// Main body
WaterTemperatureProvider.propTypes = {
  children: PropTypes.any
};
export default function WaterTemperatureProvider({ children }) {
  const [temp, setTemp] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const { wsEventData } = useContext(WebSocketDataStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);

  // Get historical data
  useEffect(() => {
    if (subscriptionData.currentTank) {
      const initTime = Math.floor((Date.now() - timeScale) / 1000);

      let initTemp = [];
      fetch(
        apiUrl.replace('__TANK__', subscriptionData.currentTank) + initTime,
        {
          method: 'GET',
          credentials: 'include'
        }
      )
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(res => {
                initTemp = res;
                setTemp(initTemp);
                setLoading(false);
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            response.reject(new Error('Unexpected response'));
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [subscriptionData.currentTank]);

  useEffect(() => {
    if (wsEventData.temp0) {
      setTemp(temp => [
        ...temp.filter(filterByTimestamp, Date.now() - timeScale),
        {
          t: wsEventData.temp0.temp,
          ts: wsEventData.temp0.time
        }
      ]);
    }
  }, [wsEventData.temp0]);

  return <Provider value={{ temp, loading }}>{children}</Provider>;
}
