import React, { useContext } from 'react';
import CameraShot from './CameraShot';
import Button from '@material-ui/core/Button';
import ws from '../../webSocket';
import { PictureStore } from '../../dataStore/camera/Picture';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeycloakStore } from '../../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../../dataStore/user/Subscription';
import PropTypes from 'prop-types';

CameraControl.propTypes = {
  maxWidth: PropTypes.number
};

export default function CameraControl(props) {
  const { state, dispatch } = useContext(PictureStore);
  const { checkPermissions, keycloak } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const permitted = checkPermissions(subscriptionData.currentTank, ['command']);

  const handleClick = () => {
    if (ws.state() === 1) {
      keycloak.updateToken(30).then(() => {
        ws.send({
          command: {
            token: keycloak.token,
            tank: subscriptionData.currentTank,
            'update-latest-picture': 1
          }
        });
      });
    }
    dispatch({ type: 'LOAD', tank: subscriptionData.currentTank });
  };

  return (
    <div>
      <CameraShot maxWidth={props.maxWidth} />
      {permitted && (
        <div>
          {!state.loading && (
            <Button variant="contained" color="default" onClick={handleClick}>
              Uppdatera bild
            </Button>
          )}
          {state.loading && <CircularProgress size={32} />}
        </div>
      )}
    </div>
  );
}
