import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { WebSocketDataStore } from '../WebSocketData';
import {
  startOfToday,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds
} from 'date-fns';
import ws from '../../webSocket';

export function getOnTime(onTime) {
  if (!onTime) return startOfToday();
  const today = startOfToday();
  const onTimeHours = setHours(today, onTime.split(':')[0]);
  const onTimeMinutes = setMinutes(onTimeHours, onTime.split(':')[1]);
  return onTimeMinutes;
}

export function getOffTime(offTime) {
  if (!offTime) return startOfToday();
  const today = startOfToday();
  const offTimeHours = setHours(today, offTime.split(':')[0]);
  const offTimeMinutes = setMinutes(offTimeHours, offTime.split(':')[1]);
  const offTimeSeconds = setSeconds(offTimeMinutes, 59);
  const offTimeMilliseconds = setMilliseconds(offTimeSeconds, 999);
  return offTimeMilliseconds;
}

const initialState = {
  onTime: undefined,
  offTime: undefined
};

const LightingScheduleStore = createContext(initialState);
export { LightingScheduleStore };
const { Provider } = LightingScheduleStore;
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      if (ws.state() === 1 && state) {
        if (
          state.onTime !== action.value.onTime ||
          state.offTime !== action.value.offTime
        ) {
          if (state.onTime && state.offTime) {
            // Only authorized user change contains token.
            if (action.value.keycloakToken && action.value.tank) {
              ws.send({
                'update-config': {
                  token: action.value.keycloakToken,
                  tank: action.value.tank,
                  relay: {
                    schedule: [action.value.onTime, action.value.offTime]
                  }
                }
              });
            }
          }
        }
      }
      return {
        onTime: action.value.onTime,
        offTime: action.value.offTime
      };
    }
    default:
      throw new Error();
  }
};

LightingScheduleProvider.propTypes = {
  children: PropTypes.any
};

export default function LightingScheduleProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wsConfigData } = useContext(WebSocketDataStore);

  useEffect(
    state => {
      if ('relay' in wsConfigData) {
        if ('schedule' in wsConfigData.relay) {
          let needsUpdate = false;
          if (!state) {
            needsUpdate = true;
          } else if (
            state.onTime !== wsConfigData.relay.schedule[0] ||
            state.offTime !== wsConfigData.relay.schedule[1]
          ) {
            needsUpdate = true;
          }

          if (needsUpdate) {
            dispatch({
              type: 'UPDATE',
              value: {
                onTime: wsConfigData.relay.schedule[0],
                offTime: wsConfigData.relay.schedule[1]
              }
            });
          }
        }
      }
    },
    [wsConfigData]
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
