import React from 'react';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { LightingStateStore } from '../../dataStore/lighting/State';

LightingStateSwitch.propTypes = {
  disabled: PropTypes.bool
};

export default function LightingStateSwitch({ disabled }) {
  const { state } = useContext(LightingStateStore);
  const checked = state === 'on' ? true : false;

  return (
    <div>
      Av
      <Switch checked={checked} disabled={disabled} />
      På
    </div>
  );
}
