import React from 'react';
import { useContext } from 'react';
import { LightingManualControlStateStore } from '../../dataStore/lighting/ManualControlState';

const min = 1;
const max = 3600;

export default function LightingManualControlTimeInput() {
  const { MCTime, setMCTime } = useContext(LightingManualControlStateStore);

  const handleChange = event => {
    if (event.target.value < min || event.target.value > max) return;
    setMCTime(event.target.value * 1000);
  };

  return (
    <div>
      Styr belysningen i&nbsp;
      <input
        type="number"
        min={min}
        max={max}
        value={Math.floor(MCTime / 1000)}
        size={6}
        onChange={handleChange}
      />
      &nbsp;sekunder
    </div>
  );
}
