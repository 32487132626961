import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TemperatureGraph from './dashboardComponents/TemperatureGraph';
import WaterTemperatureAlarmControl from './dashboardComponents/WaterTemperatureAlarmControl';
import LightingScheduleControl from './dashboardComponents/LightingScheduleControl';
import CameraControl from './dashboardComponents/CameraControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 2,
    paddingRight: 2
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

export default function ControlRoomLayout() {
  const classes = useStyles();

  return (
    <>
      <AppBar marginBottom={74} />
      <CssBaseline />
      <div className={classes.root}>
        <Grid container spacing={0} justify="center">
          <Grid container item xs={12} spacing={1} style={{ marginBottom: 4 }}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <CameraControl maxWidth={447} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <TemperatureGraph type="air" height={300} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <LightingScheduleControl size="small" />
              <div style={{ marginBottom: 10 }} />
              <WaterTemperatureAlarmControl size="small" />
            </Grid>
            <Grid item xs={6}>
              <TemperatureGraph type="water" height={300} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
