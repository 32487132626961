import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Plotly from 'plotly.js';
import locale from 'plotly.js-locales/sv';
import createPlotlyComponent from 'react-plotly.js/factory';

Plotly.register(locale);
Plotly.setPlotConfig({ locale: 'sv' });
const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles({
  plot: {
    width: '100%'
  }
});

const initXaxis = {
  low: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
  high: new Date()
};

TemperaturePlot.propTypes = {
  data: PropTypes.array,
  layout: PropTypes.object
};

export default function TemperaturePlot({ data, layout }) {
  const classes = useStyles();
  const [xaxisRangeLow, setXaxisRangeLow] = useState(initXaxis.low);
  const [xaxisRangeHigh, setXaxisRangeHigh] = useState(initXaxis.high);
  const [timeScale, setTimeScale] = useState(24 * 7); // One week

  const changeToWeek = () => {
    setTimeScale(24 * 7);
  };
  const changeTo24H = () => {
    setTimeScale(24);
  };
  const changeTo1H = () => {
    setTimeScale(1);
  };

  useEffect(() => {
    setXaxisRangeLow(new Date(Date.now() - 1000 * 60 * 60 * timeScale));
    setXaxisRangeHigh(new Date());
  }, [data, timeScale]);

  const myLayout = {
    ...layout,
    xaxis: {
      ...layout.xaxis,

      // Ref: https://plotly.com/chart-studio-help/date-format-and-time-series/
      tickformat:
        timeScale === 24 * 7
          ? '%m/%d %H:%M'
          : timeScale === 24
          ? '%a %H:%M'
          : timeScale === 1
          ? '%X'
          : '%c',
      range: [xaxisRangeLow, xaxisRangeHigh]
    }
  };

  return (
    <Paper elevation={1}>
      <Plot
        className={classes.plot}
        data={data}
        layout={myLayout}
        useResizeHandler={true}
      />
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button
          color={timeScale === 1 ? 'secondary' : 'default'}
          onClick={changeTo1H}
        >
          Timme
        </Button>
        <Button
          color={timeScale === 24 ? 'secondary' : 'default'}
          onClick={changeTo24H}
        >
          Dygn
        </Button>
        <Button
          color={timeScale === 24 * 7 ? 'secondary' : 'default'}
          onClick={changeToWeek}
        >
          Vecka
        </Button>
      </ButtonGroup>
      <br />
      <br />
    </Paper>
  );
}
