import React from 'react';
import './App.css';
import KeycloakProvider from './dataStore/user/KeycloakClient';
import UserSubscriptionProvider from './dataStore/user/Subscription';
import WebSocketDataProvider from './dataStore/WebSocketData';
import WaterTemperatureProvider from './dataStore/WaterTemperature';
import AirTemperatureProvider from './dataStore/AirTemperature';
import WaterTemperatureRangeProvider from './dataStore/WaterTemperatureRange';
import LightingScheduleProvider from './dataStore/lighting/Schedule';
import LightingStateProvider from './dataStore/lighting/State';
import LightingManualControlStateProvider from './dataStore/lighting/ManualControlState';
import PictureProvider from './dataStore/camera/Picture';
import LayoutSelector from './components/LayoutSelector.js';

function App() {
  return (
    <div className="App">
      <KeycloakProvider>
        <UserSubscriptionProvider>
          <WebSocketDataProvider>
            <WaterTemperatureProvider>
              <AirTemperatureProvider>
                <WaterTemperatureRangeProvider>
                  <LightingScheduleProvider>
                    <LightingStateProvider>
                      <LightingManualControlStateProvider>
                        <PictureProvider>
                          <LayoutSelector />
                        </PictureProvider>
                      </LightingManualControlStateProvider>
                    </LightingStateProvider>
                  </LightingScheduleProvider>
                </WaterTemperatureRangeProvider>
              </AirTemperatureProvider>
            </WaterTemperatureProvider>
          </WebSocketDataProvider>
        </UserSubscriptionProvider>
      </KeycloakProvider>
    </div>
  );
}

export default App;
