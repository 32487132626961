import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import ws from '../../webSocket';
import { KeycloakStore } from '../user/KeycloakClient';
import { UserSubscriptionStore } from '../user/Subscription';

const initialState = {
  manualControlState: 'default'
};

const LightingManualControlStateStore = createContext(initialState);
export { LightingManualControlStateStore };
const { Provider } = LightingManualControlStateStore;
const reducer = (MCState, action) => {
  switch (action.type) {
    case 'ON': {
      return {
        manualControlState: 'on'
      };
    }
    case 'OFF': {
      return {
        manualControlState: 'off'
      };
    }
    case 'RESET': {
      return initialState;
    }
    default:
      throw new Error();
  }
};

LightingManualControlStateProvider.propTypes = {
  children: PropTypes.any
};

export default function LightingManualControlStateProvider({ children }) {
  const [MCState, MCDispatch] = useReducer(reducer, initialState);
  const [MCTime, setMCTime] = useState(1000 * 30);
  const { keycloak } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);

  useEffect(() => {
    if (
      MCState.manualControlState === 'on' ||
      MCState.manualControlState === 'off'
    ) {
      if (ws.state() === 1) {
        const manualUntil = new Date().getTime() + MCTime;
        keycloak.updateToken(30).then(() => {
          ws.send({
            'update-config': {
              token: keycloak.token,
              tank: subscriptionData.currentTank,
              relay: {
                manual: MCState.manualControlState === 'on' ? 1 : 0,
                'manual-until': new Date(manualUntil).toISOString()
              }
            }
          });
        });
      }
      const interval = setInterval(() => MCDispatch({ type: 'RESET' }), MCTime);
      return () => clearInterval(interval);
    }
  });

  return (
    <Provider value={{ MCState, MCDispatch, MCTime, setMCTime }}>
      {children}
    </Provider>
  );
}
