import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ws from '../webSocket';

const initialWsEventData = {};
const initialWsConfigData = {};
const initialWsErrorData = {};
const WebSocketDataStore = createContext({
  initialWsEventData,
  initialWsConfigData,
  initialWsErrorData
});
export { WebSocketDataStore };
const { Provider } = WebSocketDataStore;

WebSocketDataProvider.propTypes = {
  children: PropTypes.any
};

export default function WebSocketDataProvider({ children }) {
  const [wsEventData, setWsEventData] = useState(initialWsEventData);
  const [wsConfigData, setWsConfigData] = useState(initialWsConfigData);
  const [wsErrorData, setWsErrorData] = useState(initialWsErrorData);

  useEffect(() => {
    ws.connect();
    ws.waitForSocketConnection(() => {
      ws.setOnmessage(function(e) {
        try {
          const data = JSON.parse(e.data);
          if ('event' in data) {
            let timestamp = Date.now() / 1000;
            if ('publishTime' in data.event) {
              timestamp = data.event.publishTime;
            }
            if ('temp0' in data.event) {
              setWsEventData(wsEventData => ({
                ...wsEventData,
                temp0: { temp: data.event.temp0, time: timestamp }
              }));
            }
            if ('temp1' in data.event) {
              setWsEventData(wsEventData => ({
                ...wsEventData,
                temp1: { temp: data.event.temp1, time: timestamp }
              }));
            }
            if ('relay' in data.event) {
              setWsEventData(wsEventData => ({
                ...wsEventData,
                relay: data.event.relay
              }));
            }
            if ('latest-picture-updated' in data.event) {
              setWsEventData(wsEventData => ({
                ...wsEventData,
                latestPictureUpdated: timestamp
              }));
            }
          }
          if ('config' in data) {
            setWsConfigData(data.config);
          }
          if ('error' in data) {
            // Timestamp might be provided by server on later version
            setWsErrorData({ error: data.error, time: Date.now() });
          }
        } catch (error) {
          console.log(e.data);
        }
      });
    });
  }, []);

  return (
    <Provider value={{ wsEventData, wsConfigData, wsErrorData }}>
      {children}
    </Provider>
  );
}
