import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { WebSocketDataStore } from '../WebSocketData';
import { UserSubscriptionStore } from '../user/Subscription';

const serverUrl = process.env.REACT_APP_BACKEND || 'https://localhost:5000';
const pictureUrl = serverUrl + '/api/v1/tanks/__TANK__/camera/latest';

const initialState = {
  picture: undefined,
  loading: false
};

const PictureStore = createContext(initialState);
export { PictureStore };
const { Provider } = PictureStore;
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        // Adding the timestamp is a hack to force a new GET.
        // Read this link for a discussion:
        // https://stackoverflow.com/questions/1077041/refresh-image-with-a-new-one-at-the-same-url/9943419
        picture: pictureUrl.replace('__TANK__', action.tank) + '?' + Date.now(),
        loading: false
      };
    }
    case 'LOAD': {
      return { picture: state.picture, loading: true };
    }
    default:
      throw new Error();
  }
};

PictureProvider.propTypes = {
  children: PropTypes.any
};

export default function PictureProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wsEventData } = useContext(WebSocketDataStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const [updateTime, setUpdateTime] = useState(Date.now() / 1000);

  useEffect(() => {
    if (subscriptionData.currentTank) {
      dispatch({
        type: 'UPDATE',
        tank: subscriptionData.currentTank
      });
    }
  }, [subscriptionData.currentTank]);

  useEffect(() => {
    if ('latestPictureUpdated' in wsEventData) {
      if (updateTime !== wsEventData.latestPictureUpdated) {
        dispatch({
          type: 'UPDATE',
          tank: subscriptionData.currentTank
        });
        setUpdateTime(wsEventData.latestPictureUpdated);
      }
    }
  }, [wsEventData, subscriptionData.currentTank, updateTime]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
