import React, { useContext, useState, useEffect } from 'react';
import TemperaturePlot from './TemperaturePlot';
import { WaterTemperatureStore } from '../../dataStore/WaterTemperature';
import { AirTemperatureStore } from '../../dataStore/AirTemperature';
import PropTypes from 'prop-types';

TemperatureGraph.propTypes = {
  type: PropTypes.string,
  height: PropTypes.number
};

export default function TemperatureGraph({ type, height }) {
  const waterTemperature = useContext(WaterTemperatureStore);
  const airTemperature = useContext(AirTemperatureStore);

  // Plotly data prop
  const [waterTemperatureDataX, setWaterTemperatureDataX] = useState([]);
  const [waterTemperatureDataY, setWaterTemperatureDataY] = useState([]);
  useEffect(() => {
    setWaterTemperatureDataX(
      waterTemperature.temp.map(item => new Date(item.ts * 1000))
    );
    setWaterTemperatureDataY(waterTemperature.temp.map(item => item.t));
  }, [waterTemperature.temp]);

  const [airTemperatureDataX, setAirTemperatureDataX] = useState([]);
  const [airTemperatureDataY, setAirTemperatureDataY] = useState([]);
  useEffect(() => {
    setAirTemperatureDataX(
      airTemperature.temp.map(item => new Date(item.ts * 1000))
    );
    setAirTemperatureDataY(airTemperature.temp.map(item => item.t));
  }, [airTemperature.temp]);

  const commonData = {
    type: 'scatter',
    mode: 'lines'
  };
  const waterTemperatureData = {
    ...commonData,
    x: waterTemperatureDataX,
    y: waterTemperatureDataY,
    marker: { color: 'blue' }
  };
  const airTemperatureData = {
    ...commonData,
    x: airTemperatureDataX,
    y: airTemperatureDataY,
    marker: { color: 'cyan' }
  };

  // Plotly layout prop
  const commonLayout = {
    xaxis: {
      title: {
        text: 'Tid'
      }
    },
    yaxis: {
      title: {
        text: 'Temperatur (°C)'
      },
      range: [18, 28]
    },
    autosize: true,
    height: height
  };
  const waterTemperatureLayout = {
    ...commonLayout,
    title: 'Vattentemperatur'
  };
  const airTemperatureLayout = {
    ...commonLayout,
    title: 'Lufttemperatur'
  };

  return (
    <div>
      {type === 'water' && !waterTemperature.loading && (
        <TemperaturePlot
          data={[waterTemperatureData]}
          layout={waterTemperatureLayout}
        />
      )}
      {type === 'air' && !airTemperature.loading && (
        <TemperaturePlot
          data={[airTemperatureData]}
          layout={airTemperatureLayout}
        />
      )}
    </div>
  );
}
