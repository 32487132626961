import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TimePicker from './TimePicker';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LightingState from './LightingState';
import LightingManualControlSwitch from './LightingManualControlSwitch';
import LightingManualControlTimeInput from './LightingManualControlTimeInput';
import { KeycloakStore } from '../../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../../dataStore/user/Subscription';
import PropTypes from 'prop-types';

LightingScheduleControl.propTypes = {
  size: PropTypes.string
};

const useStyles = makeStyles({
  tableContainerNoScroll: {
    overflowX: 'unset'
  }
});

function createData(name, state) {
  return { name, state };
}

export default function LightingScheduleControl(props) {
  const classes = useStyles();
  const { checkPermissions } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const permitted = checkPermissions(subscriptionData.currentTank, [
    'update-config'
  ]);

  let rows = [];
  rows.push(createData('Belysningen är nu', <LightingState disabled={true} />));
  permitted &&
    rows.push(
      createData(
        <LightingManualControlTimeInput />,
        <LightingManualControlSwitch />
      )
    );
  rows.push(
    createData(
      'Schema för belysning',
      <Grid container justify="flex-end" spacing={1}>
        <Grid item xs={4}>
          <TimePicker type="onTime" />
        </Grid>
        <Grid item xs={4}>
          <TimePicker type="offTime" />
        </Grid>
      </Grid>
    )
  );

  return (
    <TableContainer
      component={Paper}
      className={classes.tableContainerNoScroll}
    >
      <Table aria-label="simple table" size={props.size}>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell scope="row">{row.name}</TableCell>
              <TableCell align="right" scope="row">
                {row.state}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
