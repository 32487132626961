const serverUrl = process.env.REACT_APP_BACKEND || 'https://localhost:5000';
const socketUrl = serverUrl.replace('http', 'ws') + '/api/v1/websocket';

// Implemented based on the solution from:
// https://stackoverflow.com/questions/58432076/websockets-with-functional-components

class WebSocketClient {
  static instance = null;

  static getInstance() {
    if (!WebSocketClient.instance)
      WebSocketClient.instance = new WebSocketClient();
    return WebSocketClient.instance;
  }

  constructor() {
    this.socketRef = null;
    this.onMessageCb = null;
    this.tank = null;
  }

  connect = () => {
    console.log('Starting WebSocket connect...');
    this.socketRef = new WebSocket(socketUrl);

    this.socketRef.onopen = () => {
      this.sendSubReq();
    };

    if (this.onMessageCb) {
      this.socketRef.onmessage = this.onMessageCb;
    }

    this.socketRef.onerror = e => {
      console.error(e);
    };

    this.socketRef.onclose = () => {
      console.log('WebSocket closed. Reconnecting in 5 seconds...');
      setTimeout(this.connect, 5000);
    };
  };

  setOnmessage = cb => {
    this.onMessageCb = cb;
    this.socketRef.onmessage = this.onMessageCb;
  };

  setTank = t => {
    if (this.tank !== t) {
      this.tank = t;
      this.sendSubReq();
    }
  };

  sendSubReq = () => {
    if (
      this.tank &&
      this.socketRef &&
      this.socketRef.readyState === WebSocket.OPEN
    ) {
      this.socketRef.send(
        JSON.stringify({
          subscribe: {
            'unsubscribe-all': true,
            tank: this.tank
          }
        })
      );
    }
  };

  send = msgObj => {
    this.waitForSocketConnection(this.socketRef.send(JSON.stringify(msgObj)));
  };

  state = () => this.socketRef.readyState;

  waitForSocketConnection = callback => {
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(() => {
      if (socket.readyState === 1) {
        if (callback != null) {
          callback();
        }
        return;
      } else {
        recursion(callback);
      }
    }, 1);
  };
}

export default WebSocketClient.getInstance();
