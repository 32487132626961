import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WebSocketDataStore } from '../WebSocketData';

const initialState = {};

const LightingStateStore = createContext(initialState);
export { LightingStateStore };
const { Provider } = LightingStateStore;

LightingStateProvider.propTypes = {
  children: PropTypes.any
};

export default function LightingStateProvider({ children }) {
  const [state, setState] = React.useState(initialState);
  const { wsEventData } = useContext(WebSocketDataStore);

  useEffect(
    state => {
      if (wsEventData.relay === 1 && state !== 'on') {
        setState({ state: 'on' });
      } else if (wsEventData.relay === 0 && state !== 'off') {
        setState({ state: 'off' });
      }
    },
    [wsEventData]
  );

  return <Provider value={state}>{children}</Provider>;
}
