import React from 'react';
import { useContext } from 'react';
import Switch from '@material-ui/core/Switch';
import { LightingManualControlStateStore } from '../../dataStore/lighting/ManualControlState';
import { LightingStateStore } from '../../dataStore/lighting/State';

export default function LightingManualControlStateSwitch() {
  const { MCState, MCDispatch } = useContext(LightingManualControlStateStore);
  const { state } = useContext(LightingStateStore);
  const checked =
    MCState.manualControlState === 'on'
      ? true
      : MCState.manualControlState === 'off'
      ? false
      : state === 'on'
      ? true
      : false;

  const handleChange = event => {
    const type = event.target.checked === true ? 'ON' : 'OFF';
    MCDispatch({
      type: type
    });
  };

  return (
    <div>
      Släck
      <Switch checked={checked} onChange={handleChange} />
      Tänd
    </div>
  );
}
