import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { LayoutContext } from './LayoutSelector';

LayoutChooserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

function LayoutChooserDialog(props) {
  const { onClose, open } = props;
  let { layout, setLayout } = useContext(LayoutContext);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = value => {
    onClose();
    setLayout(value);
  };

  const layouts = ['vertical', 'control-room'];
  if (!layouts.includes(layout)) layout = 'vertical';

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="layout-chooser-dialog-title"
      open={open}
    >
      <DialogTitle id="layout-chooser-dialog-title">Välj layout</DialogTitle>
      <List>
        <ListItem
          button
          onClick={() => handleListItemClick('vertical')}
          key={'vertical'}
          selected={layout === 'vertical'}
        >
          <ListItemText primary={'Vertikal'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick('control-room')}
          key={'control-room'}
          selected={layout === 'control-room'}
        >
          <ListItemText primary={'Kontrollrum'} />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default LayoutChooserDialog;
