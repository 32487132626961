import React, { createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';
import KeycloakAuthorization from 'keycloak-js/dist/keycloak-authz';

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
});

const keycloakInitConfig = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256'
};

// The instantiation of KeycloakAuthorization should be after
// Keycloak.init()
let keycloakAuthz;

const initialState = {
  authzEntitled: false,
  keycloakReady: false,
  username: undefined,
  authenticated: false,
  storedPermissions: null
};

const KeycloakStore = createContext({ initialState, keycloak });
export { KeycloakStore };

const { Provider } = KeycloakStore;

KeycloakAuthzProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default function KeycloakAuthzProvider({ children }) {
  const [authState, setAuthzState] = React.useState(initialState);
  console.debug(authState);

  const checkPermissions = (resource, scopes) => {
    if (
      !keycloak.authenticated ||
      authState.storedPermissions == null ||
      !resource
    )
      return false;

    for (const p of authState.storedPermissions) {
      if (p.rsname === resource) {
        let allScopes = true;
        for (const s of scopes) {
          if (!p.scopes.includes(s)) allScopes = false;
        }
        if (allScopes) return true;
      }
    }

    return false;
  };

  const onReady = () => {
    console.debug('kc onReady');
    setAuthzState(state => {
      return {
        ...state,
        keycloakReady: true
      };
    });
  };

  const onAuthSuccess = () => {
    // This event comes earlier than the completion of Keycloak init()
    console.debug('kc onAuthSuccess');
    keycloak
      .loadUserProfile()
      .then(profile => {
        setAuthzState(state => {
          return {
            ...state,
            username: profile.username
          };
        });
      })
      .catch(() => {
        alert('Failed to load user profile');
        keycloak.clearToken();
      });
  };

  const onAuthError = () => {
    console.error('There was an error during authentication');
  };

  const onAuthRefreshSuccess = () => {
    console.debug('kc onAuthRefreshSuccess');
  };

  const onAuthRefreshError = () => {
    console.error('There was an error while trying to refresh the token');
  };

  const onAuthLogout = useCallback(() => {
    console.debug('kc onAuthLogout');
    if (authState.authenticated) {
      setAuthzState(state => {
        return {
          ...state,
          authenticated: false
        };
      });
    }
    // Use non-blocking logging to allow WS messages keep on coming and being processed.
    console.log('Du är utloggad.');
  }, [authState.authenticated]);

  const onTokenExpired = () => {
    console.debug('kc onTokenExpired');
    keycloak
      .updateToken(5)
      .then(refreshed => {
        if (refreshed) {
          console.debug('Token was successfully refreshed');
        } else {
          console.debug('Token is still valid');
        }
      })
      .catch(() => {
        console.warn('Failed to refresh the token, or the session has expired');
        keycloak.clearToken();
      });
  };

  useEffect(() => {
    keycloak.onReady = onReady;
    keycloak.onAuthSuccess = onAuthSuccess;
    keycloak.onAuthError = onAuthError;
    keycloak.onAuthRefreshSuccess = onAuthRefreshSuccess;
    keycloak.onAuthRefreshError = onAuthRefreshError;
    keycloak.onTokenExpired = onTokenExpired;

    keycloak
      .init(keycloakInitConfig)
      .then(authenticated => {
        console.debug('kc auth:', authenticated);
        if (authenticated) {
          keycloakAuthz.init();
          console.debug(keycloakAuthz.config);
          setAuthzState(state => {
            return {
              ...state,
              authenticated: true
            };
          });
        }
      })
      .catch(() => {
        console.error('Unable to communicate with Keycloak');
      });

    keycloakAuthz = new KeycloakAuthorization(keycloak);
  }, []);

  useEffect(() => {
    keycloak.onAuthLogout = onAuthLogout;
  }, [onAuthLogout]);

  useEffect(() => {
    if (
      !authState.authzEntitled &&
      authState.authenticated &&
      authState.keycloakReady
    ) {
      keycloakAuthz
        .entitlement(process.env.REACT_APP_KEYCLOAK_BACKEND_CLIENT_ID)
        .then(
          rpt => {
            console.debug('authz granted');
            var j = JSON.parse(atob(rpt.split('.')[1]));
            setAuthzState(state => {
              return {
                ...state,
                authzEntitled: true,
                storedPermissions: j.authorization.permissions
              };
            });
          },
          () => {
            console.debug('authz denied');
            setAuthzState(state => {
              return {
                ...state,
                authzEntitled: true,
                storedPermissions: null
              };
            });
          },
          () => {
            console.debug('authz error');
            setAuthzState(state => {
              return {
                ...state,
                authzEntitled: true,
                storedPermissions: null
              };
            });
          }
        );
    }
  }, [authState]);

  return (
    <Provider value={{ authState, checkPermissions, keycloak }}>
      {children}
    </Provider>
  );
}
