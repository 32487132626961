import { format } from 'date-fns';
import React, { useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  TimePicker as ImportedTimePicker
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import {
  getOnTime,
  getOffTime,
  LightingScheduleStore
} from '../../dataStore/lighting/Schedule';
import { KeycloakStore } from '../../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../../dataStore/user/Subscription';

TimePicker.propTypes = {
  type: PropTypes.string
};

export default function TimePicker({ type }) {
  const { state, dispatch } = useContext(LightingScheduleStore);
  const { checkPermissions, keycloak } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const permitted = checkPermissions(subscriptionData.currentTank, [
    'update-config'
  ]);

  const onTime = getOnTime(state.onTime);
  const offTime = getOffTime(state.offTime);

  const handleScheduleChange = date => {
    if (isNaN(date) === false) {
      keycloak.updateToken(30).then(() => {
        dispatch({
          type: 'UPDATE',
          value: {
            onTime: type === 'onTime' ? format(date, 'HH:mm') : state.onTime,
            offTime: type === 'offTime' ? format(date, 'HH:mm') : state.offTime,
            keycloakToken: keycloak.token,
            tank: subscriptionData.currentTank
          }
        });
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ImportedTimePicker
        disabled={!permitted}
        ampm={false}
        margin="normal"
        id={type === 'onTime' ? 'onTime-picker' : 'offTime-picker'}
        label={type === 'onTime' ? 'Från' : 'Till'}
        value={type === 'onTime' ? onTime : offTime}
        onChange={handleScheduleChange}
      />
    </MuiPickersUtilsProvider>
  );
}
