import React, { useState, createContext } from 'react';
import VerticalLayout from './VerticalLayout';
import ControlRoomLayout from './ControlRoomLayout';

const LayoutContext = createContext({ layout: '', setLayout: () => {} });

export default function LayoutSelector() {
  const [layout, setLayout] = useState(localStorage.getItem('layout'));

  const setLayoutPersistently = value => {
    if (value !== layout) {
      localStorage.setItem('layout', value);
      setLayout(value);
    }
  };

  const chosenLayout =
    layout === 'control-room' ? <ControlRoomLayout /> : <VerticalLayout />;

  return (
    <LayoutContext.Provider
      value={{ layout, setLayout: setLayoutPersistently }}
    >
      {chosenLayout}
    </LayoutContext.Provider>
  );
}

export { LayoutContext };
