import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { UserSubscriptionStore } from '../dataStore/user/Subscription';

TankChooserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

function TankChooserDialog(props) {
  const { onClose, selectedValue, open } = props;
  const { subscriptionData } = useContext(UserSubscriptionStore);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="tank-chooser-dialog-title"
      open={open}
    >
      <DialogTitle id="tank-chooser-dialog-title">
        Välj tillgänglig tank
      </DialogTitle>
      <List>
        {subscriptionData.tanks.map(sub => (
          <ListItem button onClick={() => handleListItemClick(sub)} key={sub}>
            <ListItemText primary={sub} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default TankChooserDialog;
